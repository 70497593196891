export enum LocaleTag {
  EN = 'en',
  FR = 'fr',
}
export interface Locale {
  default?: boolean;
  path: string;
  label: string;
  short: LocaleTag;
}

const locales: Locale[] = [
  {
    default: true,
    path: 'en',
    label: 'English',
    short: LocaleTag.EN,
  },
  {
    path: 'fr',
    label: 'Français',
    short: LocaleTag.FR,
  },
];

export const defaultLocaleTag: LocaleTag = (() => {
  const foundDefaultLanguage = locales.find((locale) => locale.default)?.short;
  if (!foundDefaultLanguage) {
    throw new Error(
      `Cannot find the default locale tag. Did you forget to mark a locale as default in '/config/locales.ts'?`
    );
  }
  return foundDefaultLanguage;
})();

export const defaultLocale: Locale = (() => {
  const foundLocale = locales.find((locale) => locale.default);
  if (!foundLocale) {
    throw new Error(
      `Cannot find the default locale. Did you forget to mark a locale as default in '/config/locales.ts'?`
    );
  }
  return foundLocale;
})();

export const shortLocales: LocaleTag[] = (() => {
  return locales.map((locale) => locale.short);
})();

export default locales;
