import React from 'react';
import { withPrefix, GatsbySSR } from 'gatsby';
import { WindowLocation, navigate } from '@reach/router';
import { LocaleProvider } from '../../context/Locale';
import { IntlContext } from '../node/onCreatePage';
import { LocalStorageKeys } from '../../keys/LocalStorageKeys';
import { defaultLocaleTag } from '../../../config/locales';
import i18n from '../../localization/i18next';

export interface wrapPageElementProps {
  element: React.ReactNode;
  props: {
    path: string;
    location: WindowLocation;
    pageContext: {
      intl: IntlContext;
    };
  };
}
/**
 * Wrap all the pages with a Locale provider
 */
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }: any) => {
  // We don't need to process these pages since they are not directly associated with our application
  if (props.path === '/dev-404-page/' || props.path === '/offline-plugin-app-shell-fallback/') {
    return element as any;
  }

  const {
    pageContext: { intl },
    location,
  } = props;

  const { routed, originalPath, mappedRoute, localeTag } = intl;

  const { search } = location;

  // Only redirect if user is trying to access non-language associated page.
  // Example:
  // User tries to access `/` run the below mechanism
  // User tries to access `/en` don't run the below mechanism
  if (!routed) {
    if (typeof window !== 'undefined') {
      // This is being stored in i18n config
      const cachedLocaleFromi18n =
        window.localStorage.getItem(LocalStorageKeys.ACTIVE_LOCALE) || defaultLocaleTag;
      const foundPath = mappedRoute.path[cachedLocaleFromi18n] || originalPath;

      const queryParams = search || '';
      const newUrl = withPrefix(`/${cachedLocaleFromi18n}${foundPath}${queryParams}`);
      navigate(newUrl, { replace: true });
    }
  }

  // If it is during build, use the Language detector and se the language so that all the static pages are hydrated with the content
  // This is a "hack" due to Gatsby's lack of support for i18n and some SSR issues during build time
  if (typeof window === 'undefined') {
    i18n.changeLanguage(localeTag);
  }

  return <LocaleProvider {...props}>{element}</LocaleProvider>;
};

export default wrapPageElement;
