import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { GatsbySSR } from 'gatsby';
import i18n from '../../localization/i18next';

/**
 * Wrap the root element with a Translation provider
 */
const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }: any): any => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export { wrapRootElement };
