import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { shortLocales, defaultLocaleTag } from '../../config/locales';
import { LocalStorageKeys } from '../keys/LocalStorageKeys';
import { commonTranslations, LocaleNameSpaceKeys } from './translations';
import isWindowUndefined from '../shared/utils/isWindowUndefined';

export const options = {
  resources: commonTranslations,
  ns: Object.keys(LocaleNameSpaceKeys),
  defaultNS: LocaleNameSpaceKeys.COMMON,
  fallbackLng: defaultLocaleTag,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  whitelist: shortLocales,
  react: {
    useSuspense: true,
    wait: false,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 'em', 'sup'],
  },
  detection: {
    // order and from where user language should be detected
    order: ['path', 'localStorage', 'navigator'],
    checkWhitelist: true,
    checkForSimilarInWhitelist: true,
    lookupFromPathIndex: 0,
    lookupLocalStorage: LocalStorageKeys.ACTIVE_LOCALE,
  },

  debug: !!process.env.GATSBY_DEBUG_LOCALIZATION,
};

// If it is not build, use the Language detector
if (!isWindowUndefined()) {
  i18n.use(LanguageDetector);
}

i18n.use(initReactI18next).init(options);

export default i18n;
