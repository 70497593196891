import React, { createContext } from 'react';

import { navigate, WindowLocation } from '@reach/router';
import { LocaleTag, defaultLocaleTag } from '../../config/locales';
import { IntlContext } from '../gatsby/node/onCreatePage';
import i18n from '../localization/i18next';
import { NavigationRoute } from '../../config/localizedRoutes';

export interface LocaleContext {
  updateLocale: (tag: LocaleTag) => Promise<void>;
  activeLocaleTag: LocaleTag;
  mappedRoute?: NavigationRoute;
  location?: WindowLocation;
  originalPath: string | null;
}
const LocaleContext = createContext<LocaleContext>({
  updateLocale: async () => {},
  activeLocaleTag: defaultLocaleTag,
  originalPath: null,
});

const useLocaleContext = () => React.useContext(LocaleContext);

const LocaleProvider: React.FC<Props> = (props) => {
  const {
    children,
    location,
    pageContext: { intl },
  } = props;

  const updateLocale = async (tag: LocaleTag): Promise<void> => {
    const foundPath = intl.mappedRoute.path[tag];
    await i18n.changeLanguage(tag);
    if (foundPath) {
      // if the path is found, redirect the user to the path
      navigate(`/${tag.toLowerCase()}${foundPath}${location.search}`);
      return;
    }
    navigate(`/${tag}${location.search}`);
  };

  return (
    // LocaleContext is being supplied as value down below
    <LocaleContext.Provider
      value={{
        activeLocaleTag: i18n.language as LocaleTag,
        updateLocale,
        location,
        mappedRoute: intl.mappedRoute,
        originalPath: intl.originalPath,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

interface OwnProps {
  location: WindowLocation;
  pageContext: {
    intl: IntlContext;
  };
}

type Props = OwnProps;

export { LocaleProvider, useLocaleContext, LocaleContext as default };
